import './App.css';
import { useConnectWallet, useWallets } from "@web3-onboard/react";
import { useEffect, useState } from 'react';
import { initWeb3Onboard } from './services';
import { OnboardAPI } from '@web3-onboard/core';
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { Web3Provider } from '@ethersproject/providers';
import { Polycentral__factory } from './typechain';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';


const MAINNET_CONTRACT_ADDRESS = "0x50333672e81463285Ba9d231E8280575Ebb7F2BD";

function Mint() {
  const [{ wallet }, connect] = useConnectWallet();
  const connectedWallets = useWallets();

  const [web3Onboard, setWeb3Onboard] = useState<OnboardAPI | null>(null);

  useEffect(() => {
    console.log("init");
    setWeb3Onboard(initWeb3Onboard)
  }, []);

  useEffect(() => {
    console.log("connect");
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )
  }, [connectedWallets, wallet]);

  useEffect(() => {
    console.log("onboard");
    const setWalletFromLocalStorage = async (previouslyConnectedWallets: any) => {
      console.log("set wallet from local storage");
      await connect({ autoSelect: previouslyConnectedWallets[0] });
    }

    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')!
    );

    if (previouslyConnectedWallets?.length) {
      setWalletFromLocalStorage(previouslyConnectedWallets);
    }
  }, [web3Onboard, connect]);

  return (
    <div>
      <MintHero />
      <section id="whatbar"></section>
    </div>
  )
}

export default Mint;

function MintHero() {
  const [{ wallet }, connect] = useConnectWallet();
  const [quantity, setQuantity] = useState(1);
  const [provider, setProvider] = useState<Web3Provider | null>(null);
  const [minted, setMinted] = useState<string>();

  useEffect(() => {
    const updateMinted = async () => {
      if (!provider) {
        setMinted("?");
        return;
      }
      const rem = await getMinted(provider);
      setMinted(rem);
    };
    updateMinted();
  }, [wallet, provider, minted])

  useEffect(() => {
    if (!wallet?.provider) {
      setProvider(null)
    } else {
      console.log("set provider ok");
      setProvider(new ethers.providers.Web3Provider(wallet.provider, 'any'));
    }
  }, [wallet]);

  const handleMint = (amount: number) => {
    const promise = sendMint(amount);
    toast.promise(promise, {
      loading: "Please confirm the transaction.",
      success: () => {
        return "Mint initiated."
      },
      error: (msg) => msg ? msg.message : "An unexpected error was encountered during your transaction."
    })
  };

  const sendMint = async (amount: number): Promise<boolean> => {
    if (provider && wallet?.accounts[0]) {
      const address = wallet.accounts[0].address;
      try {
        const polycentral = Polycentral__factory.connect(MAINNET_CONTRACT_ADDRESS, provider.getSigner());
        const pricePer = await polycentral.mintPrice();
        await polycentral.publicMint(
          address,
          amount,
          { value: pricePer.mul(amount) }
        );
      }
      catch (err) {
        console.log(err);
        throw new Error("Transaction failed to initiate.");
      }
      return true;
    }
    else {
      return false;
    }
  }

  const inc = () => {
    setQuantity((prev) => prev + 1);
  }

  const dec = () => {
    // Cannot go to 0
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  }

  return <div className="hero headerbg">
    <div className="container">
      <div className="hero-wrapper">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-7">
            <div className="hero-left">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <h2>MINTING IS OPEN</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h3>Ξ0.02 per mint</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h3>{minted}/1000 minted</h3>
                  </div>
                </div>
                {
                  (wallet != null) ?
                    <div className="row">
                      <div className="col qty">
                        <div className="container-fluid">
                          <div className="row text-center align-items-center">
                            <div className="col text-center">
                              <button onClick={() => dec()}><Minus width="100%" /></button>
                            </div>
                            <div className="col-6 text-center">
                              <p>{quantity} POLYCENTRAL</p>
                            </div>
                            <div className="col text-center">
                              <button onClick={() => inc()}><Plus width="100%" /></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                <div className="row text-center">
                  <div className="col">
                    {
                      wallet ?
                        <button onClick={() => handleMint(quantity)}>MINT YOUR POLYCENTRAL</button> :
                        <button onClick={() => connect({})}>CONNECT WALLET</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="hero-right">
              <video className="img-fluid" autoPlay loop muted></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
}

async function getMinted(provider: Web3Provider) {
  const polycentral = Polycentral__factory.connect(MAINNET_CONTRACT_ADDRESS, provider);
  return (await polycentral.totalSupply()).toString();
}

// function hash(account: string): Buffer {
//   return Buffer.from(
//     ethers.utils.solidityKeccak256(
//       ['address'],
//       [account]
//     ).slice(2), 'hex');
// }