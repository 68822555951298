import './App.css';
import header_icon_one from "./img/header-icon-one.png"
import header_icon_two from "./img/header-icon-two.png"
import header_icon_three from "./img/header-icon-three.png"
import { Link, Outlet } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;

function Footer() {
  return <footer>
    <section id="header">
      <div className="container">
        <div className="header-wrapper">
          <div className="header-left">
            <Link to="/"><span>Polycentral</span></Link>
          </div>
          <div className="header-right">
            <ul>
              <li><a href="https://opensea.io/collection/polycentral" target="_blank" rel="noreferrer"><img src={header_icon_one} alt="images not found" /></a></li>
              <li><a href="https://twitter.com/tompop99" target="_blank" rel="noreferrer"><img src={header_icon_two} alt="images not found" /></a></li>
              <li><a href="https://discord.gg/k8HwQZqapB" target="_blank" rel="noreferrer"><img src={header_icon_three} alt="images not found" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </footer>;
}

function Header() {
  return <div className="headerbg">
    <section id="header">
      <div className="container">
        <div className="header-wrapper">
          <div className="header-left">
            <Link to="/"><span>Polycentral</span></Link>
          </div>
          <div className="header-right">
            <ul>
              <li><a href="https://opensea.io/collection/polycentral" target="_blank" rel="noreferrer"><img src={header_icon_one} alt="images not found" /></a></li>
              <li><a href="https://twitter.com/tompop99" target="_blank" rel="noreferrer"><img src={header_icon_two} alt="images not found" /></a></li>
              <li><a href="https://discord.gg/k8HwQZqapB" target="_blank" rel="noreferrer"><img src={header_icon_three} alt="images not found" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>;
}
