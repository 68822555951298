import './App.css';
import emerald from "./img/emerald.jpeg"
import tompop from "./img/tompop.jpeg"
import two from "./img/vid/2x2.mp4"
import three from "./img/vid/3x3.mp4"
import four from "./img/vid/4x4.mp4"
import five from "./img/vid/5x5.mp4"
import six from "./img/vid/6x6.mp4"
import vid1 from "./img/vid/vid-1.mp4"
import vid2 from "./img/vid/vid-2.mp4"
import vid3 from "./img/vid/vid-3.mp4"
import example4 from "./img/vid/994.mp4"
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div>
      <Hero />
      <What />
      <Who />
      <Faq />
    </div>
  );
}

export default Home;

function Hero() {
  return <div className="hero headerbg">
    <span id="bar"></span>
    <div className="container">
      <div className="hero-wrapper">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-7">
            <div className="hero-left">
              <h2>A NEW KIND OF <br />
                COLLECTABLE</h2>
              <p>999 animated NFTs, inspired by cities.<br />
                Trillions of unique possible combinations.</p>
              <Link to="/mint"><button>MINTING <br /> NOW</button></Link>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="hero-right">
              <video className="img-fluid" autoPlay loop muted><source src={example4} type="video/mp4" /></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
}


function Faq() {
  return <section id="faq">
    <span></span>
    <div className="container">
      <div className="faq-wrapper">
        <h2>FAQ</h2>
      </div>
      <div className="faq-content">
        <div className="row">
          <div className="col-lg-7 col-md-8">
            <div className="faq-left">
              <div className="faq-text">
                <div className="faq-one faq-first">
                  <h6>What is Polycentral?</h6>
                  <p>Polycentral is a new collection of 999 animated NFTs, inspired by cities
                    and the movement we see inside them.
                  </p>
                  <p>There are nine colourways, with 111 pieces generated for each.
                    Each colour way is named after a city: Barcelona, Berlin and Dublin.
                  </p>
                  <p>Each city has 50 animations which are randomly put together to create interesting
                    combinations, across
                    2x2, 3x3, 4x4, 5x5 and 6x6 grids.<br />
                    There are trillions of possible combinations.</p>
                </div>
                <div className="faq-one">
                  <h6>How much will these fine looking pieces cost?</h6>
                  <p>0.02 ETH each.</p>
                </div>
                <div className="faq-one">
                  <h6>When can I get some?!</h6>
                  <p>Greenlist members will be able to mint their pieces on Monday, June 6th.<br /> The public sale for
                    Polycentral will
                    launch on Wednesday, June 8th.</p>
                </div>
                <div className="faq-one">
                  <h6>What's your roadmap?</h6>
                  <p>Like the art, buy the art. No promises, no broken hearts.<br /> Polycentral holders will be
                    the envy of
                    the entire internet.</p>
                </div>
              </div>
              <div className="faq-link d-none d-md-block">
              <Link to="/mint"><button>MINTING <br /> NOW</button></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-4">
            <div className="faq-right">
              <div className="faq-one">
                <video autoPlay loop muted><source src={vid1} type="video/mp4" /></video>
              </div>
              <div className="faq-one">
                <video autoPlay loop muted><source src={vid2} type="video/mp4" /></video>
              </div>
              <div className="faq-one">
                <video autoPlay loop muted><source src={vid3} type="video/mp4" /></video>
              </div>
            </div>
            <div className="faq-link d-block d-md-none">
              <Link to="/mint"><button>MINTING <br /> NOW</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>;
}

function Who() {
  return <section id="who">
    <span></span>
    <div className="container">
      <div className="who-wrapper">
        <h2>WHO IS THE TEAM?</h2>
      </div>
      <div className="who-block">
        <div className="who-block-one">
          <div className="who-block-one-left">
            <p>TOMPOP</p>
            <img src={tompop} alt="images not found" />
          </div>
          <div className="who-block-one-right">
            <p>Tompop is an artist and designer, focused mostly on 2d
              animation loops in vibrant colours. He has sold hundreds of NFTs across Ethereum and Tezos platforms in the last year.</p>
          </div>
        </div>
        <div className="who-block-one who-block-two">
          <div className="who-block-one-left">
            <p>EMERALD</p>
            <img src={emerald} alt="images not found" />
          </div>
          <div className="who-block-one-right">
            <p>Emerald is the programmer behind the succesful Wanderers project. They bring a wealth of knowledge and experience to the project.</p>
          </div>
        </div>
      </div>
      <div className="who-link">
      <Link to="/mint"><button>MINTING <br /> NOW</button></Link>
      </div>
    </div>
  </section>;
}

function What() {
  return <section id="what">
    <span id="bar"></span>
    <div className="container">
      <div className="what-wrapper">
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="what-left">
              <div className="what-left-heading">
                <h2>WHAT IS POLYCENTRAL?</h2>
              </div>
              <div className="what-left-para">
                <p>Polycentral is a new collection of 999 animated NFTs from Tompop, <br />
                  inspired by cities and the movement we see inside them.
                </p>
                <p>There are nine colour ways, with 111 pieces generated for each.<br />
                  Each colour way is named after a city: Amsterdam, Barcelona, Berlin, <br /> Dublin, Glasgow, Liverpool, Manchester,
                  New York and Vancouver.
                </p>
                <p>Each city has 50 animations which are randomly put together to create <br /> interesting combinations, across 2x2, 3x3, 4x4, 5x5 and 6x6 grids.</p>
              </div>
              <div className="what-left-block">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="what-left-block-one">
                      <p>2x2</p>
                      <video className="img-fluid" autoPlay loop muted><source src={two} type="video/mp4" /></video>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="what-left-block-one">
                      <p>3x3</p>
                      <video className="img-fluid" autoPlay loop muted><source src={three} type="video/mp4" /></video>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="what-left-block-one">
                      <p>4x4</p>
                      <video className="img-fluid" autoPlay loop muted><source src={four} type="video/mp4" /></video>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="what-left-block-one">
                      <p>5x5</p>
                      <video className="img-fluid" autoPlay loop muted><source src={five} type="video/mp4" /></video>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="what-left-block-one">
                      <p>6x6</p>
                      <video className="img-fluid" autoPlay loop muted><source src={six} type="video/mp4" /></video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="what-right">
              <div className="what-right-one text-center">
                <p>AMSTERDAM</p>
                <ul>
                  <li><span className="am1"></span></li>
                  <li><span className="am2"></span></li>
                  <li><span className="am3"></span></li>
                </ul>
              </div>
              <div className="what-right-one text-center">
                <p>BARCELONA</p>
                <ul>
                  <li><span className="bar1"></span></li>
                  <li><span className="bar2"></span></li>
                  <li><span className="bar3"></span></li>
                </ul>
              </div>
              <div className="what-right-one text-center">
                <p>BERLIN</p>
                <ul>
                  <li><span className="ber1"></span></li>
                  <li><span className="ber2"></span></li>
                  <li><span className="ber3"></span></li>
                </ul>
              </div>
              <div className="what-right-one text-center">
                <p>DUBLIN</p>
                <ul>
                  <li><span className="dub1"></span></li>
                  <li><span className="dub2"></span></li>
                  <li><span className="dub3"></span></li>
                </ul>
              </div>
              <div className="what-right-one text-center">
                <p>GLASGOW</p>
                <ul>
                  <li><span className="gla1"></span></li>
                  <li><span className="gla2"></span></li>
                  <li><span className="gla3"></span></li>
                </ul>
              </div>
              <div className="what-right-one text-center">
                <p>LIVERPOOL</p>
                <ul>
                  <li><span className="liv1"></span></li>
                  <li><span className="liv2"></span></li>
                  <li><span className="liv3"></span></li>
                </ul>
              </div>
              <div className="what-right-one text-center">
                <p>MANCHESTER</p>
                <ul>
                  <li><span className="man1"></span></li>
                  <li><span className="man2"></span></li>
                  <li><span className="man3"></span></li>
                </ul>
              </div>
              <div className="what-right-one text-center">
                <p>NEW YORK CITY</p>
                <ul>
                  <li><span className="nyc1"></span></li>
                  <li><span className="nyc2"></span></li>
                  <li><span className="nyc3"></span></li>
                </ul>
              </div>
              <div className="what-right-one text-center mb-0">
                <p>VANCOUVER</p>
                <ul>
                  <li><span className="van1"></span></li>
                  <li><span className="van2"></span></li>
                  <li><span className="van3"></span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="what-bg">
        <p>There are seven possible background colours.</p>
        <ul>
          <li><span className="ov-one"></span></li>
          <li><span className="ov-two"></span></li>
          <li><span className="ov-three"></span></li>
          <li><span className="ov-four"></span></li>
          <li><span className="ov-five"></span></li>
          <li><span className="ov-six"></span></li>
          <li><span className="ov-seven"></span></li>
        </ul>
      </div>
      <div className="what-link">
      <Link to="/mint"><button>MINTING <br /> NOW</button></Link>
      </div>
    </div>
  </section>;
}
